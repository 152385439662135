import React, {useEffect,useState } from 'react'
import '../styles/loader.css'
import loader from '../assets/2d_images/loader.gif'
import * as createjs  from '@tweenjs/tween.js';

const Loader = () => {
  const [tipIndex, setTipIndex] = useState(0);
  const elemWrapper = React.createRef(null);
  let currTween = undefined;
  const tips = [
    "360 view : Drag the mouse while pressing mouse-left key", 
    "left - right - forward - backward movements : Drag the mouse while pressing mouse-right key", 
    "zoom in/out : mouse scroll"
  ]
  
  useEffect(()=>{

    let interval = setInterval(function(){
      if(tipIndex < 2){
        setTipIndex(tipIndex + 1);
      }
    },4000);

    // elemWrapper.current.style.opacity = 0;
    // new createjs.Tween(elemWrapper.current.style).to({opacity:1},1000).start();
    // elemWrapper.current.style.opacity = 1;
    // new createjs.Tween(elemWrapper.current.style).to({opacity:0},1000).delay(2000).start();
    // let interval = setInterval(function(){
    //   if(tipIndex < 2){
    //     setTipIndex(tipIndex + 1);
    //     elemWrapper.current.style.opacity = 0;
    //     new createjs.Tween(elemWrapper.current.style).to({opacity:1},1000).start();
    //     elemWrapper.current.style.opacity = 1;
    //     new createjs.Tween(elemWrapper.current.style).to({opacity:0},1000).delay(2000).start();
    //   }
    // },4000);

    return()=>{
      clearInterval(interval);
      // createjs.removeAll();
    }
    
  },[tipIndex])

  

  return (
    <div id="loaderComp">
      {/* <video controlsList='nodownload nofullscreen noremoteplayback' width="200px" loop autoPlay preload="false" playsInline tabIndex="0" className='loader
' muted>
        <source src="loader/loader.mp4" type="video/mp4"></source>
      </video> */}
      <div className='gif-box'>
      <img src="loader/Loader2.gif" alt="loader"  className='loader'/>
      </div>
      <p className='fadein-out' ref={elemWrapper}><h4>TIP:</h4> {tips[tipIndex]} </p>
    </div>
  )
}

export default Loader