import React, { useEffect, useState } from "react";

import { ThreejsHandler } from "../../scene/ThreejsHandler";

import '../../styles/sudlifegame.css'
import Loader from "../Loader";
import Generic from "./Generic";
import { AimvestmentHandler } from "../../scene/aimvestment/AimvestmentHandler";

import FPSStats from "react-fps-stats";

const GameState = {
    STATE_LOADING : 0,
    STATE_LOADING_ERR : 1,
    STATE_WAITING_FOR_START : 2,
    STATE_RUNNING : 3,
    STATE_PAUSE : 4,
    STATE_OVER : 5
}

const SUDLifeGame = (props) => {
    const elemWrapper = React.createRef(null);
    // open:false;
    // const [isRunning, setIsRunning] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [gameState, setGameState] = useState(GameState.STATE_LOADING);

    const onAssetsLoaded = () =>{
        setGameState(GameState.STATE_RUNNING)
    }

    const onAssetsLoadErr = () =>{
        setGameState(GameState.STATE_LOADING_ERR)
    }

    const onCliclRetryAssetLoadErrPopup = () =>{
        window.location.href = process.env.REACT_APP_BASE_PATH;
    }

    const getContentBasedOnState = () =>{
        switch(gameState){
            case GameState.STATE_LOADING:
                return(<Loader/>)
            case GameState.STATE_LOADING_ERR:
                return(
                <div className="buttons">
                    <Generic popuptype={1} onClickRetry={onCliclRetryAssetLoadErrPopup}/>
                </div>)
            // case GameState.STATE_WAITING_FOR_START:
            //     return(
            //         <div className="buttons">
            //             <StartGame/>
            //         </div>)
            case GameState.STATE_RUNNING:
                return(<></>);
            // case GameState.STATE_PAUSE:
            //     return(
            //         <div className="buttons">
            //             <Generic popuptype={0} onClickYes={onClickYesExitPopup} onClickNo={onClickNoExitPopup}/>
            //         </div>)
            // case GameState.STATE_OVER:
            //     return(<Result onReplay={()=>{
            //         setGameState(GameState.STATE_WAITING_FOR_START);
            //     }}/>);
            default:
                return(<Loader/>);
        }
    }

    const onFocus = (e) =>{
        
    }

    const onFocusLost = (e) =>{
        // if(gameState === GameState.STATE_RUNNING)
        //     onClickBackButton();
    }

    useEffect(() => {
        ThreejsHandler.createInstance(elemWrapper.current);
        ThreejsHandler.getInstance().start();
        if(ThreejsHandler.getInstance().getCurrentModule() === undefined){
            ThreejsHandler.getInstance().setCurrentModule(new AimvestmentHandler(ThreejsHandler.getInstance()));
        }

        ThreejsHandler.getInstance().getCurrentModule().addEventListener("assetsLoaded", onAssetsLoaded);
        ThreejsHandler.getInstance().getCurrentModule().addEventListener("assetLoadErr", onAssetsLoadErr);

        window.addEventListener('blur', onFocusLost);
        window.addEventListener('focus',onFocus);

       
        return()=>{
            ThreejsHandler.getInstance().getCurrentModule().removeEventListener("assetsLoaded", onAssetsLoaded);
            ThreejsHandler.getInstance().getCurrentModule().removeEventListener("assetLoadErr", onAssetsLoadErr);
            window.removeEventListener('blur', onFocusLost);
            window.removeEventListener('focus',onFocus);
        }

    }, [gameState])

    return (
        <div>

            <canvas id="canvas3D"
                style={{}}
                ref={elemWrapper}
            />
            {getContentBasedOnState()}
            {
                
                // gameState === GameState.STATE_LOADING ? 
                // <Loader/> 
                // :
                // <div className="buttons">
                //     {gameState === GameState.STATE_RUNNING && <Hud />}
                //     {gameState === GameState.STATE_WAITING_FOR_START && <StartGame />}
                //     {gameState === GameState.STATE_PAUSE && <Generic popuptype={0} onClickYes={onClickYesExitPopup} onClickNo={onClickNoExitPopup}/>}
                // </div>
            }

           
            {/* <ExpandMoreIcon onClick={()=>{this.setState(!open)}} */}
            {process.env.REACT_APP_SHOW_STATS === "1" && <FPSStats/>}
        </div>);
}

export default SUDLifeGame;