import '../../styles/startgame.css'
import '../../styles/generic.css'
const PopupType = {
  EXIT_POPUP : 0,
  ASSET_LOAD_ERR : 1
}
const Generic = (props) => {
    // const [scoreData, setScoreData] = useState({score:0});
    
    const getContent = (popupType) =>{
      switch(popupType){
        case PopupType.EXIT_POPUP:
          return (
                  <div className="pop-text">
                    <h2>Are you sure you<br /> want to exit?</h2>
                    <div className='yes-no'>
                        <h3 onClick={()=>{props.onClickNo()}}>No</h3><h3 onClick={()=>{props.onClickYes()}}>Yes</h3>
                    </div>
                  </div>
                )
        case PopupType.ASSET_LOAD_ERR:
          return (
                  <div className="pop-text">
                    <h2>Assets not loaded properly, Retry!</h2>
                    <div className='yes-no'>
                        <h3 onClick={()=>{props.onClickRetry()}}>Retry</h3>
                    </div>
                  </div>
                )
          default:
            return (
              <div className="pop-text">
                <h2>Are you sure you bla bla bla<br /> want to exit?</h2>
                <div className='yes-no'>
                    <h3>No</h3><h3>Yes</h3>
                </div>
              </div>
            )
      }
    }

    return (
        <div className='resultMainContainer'>
            <div className='rsultboxFrame'>
                {getContent(props.popuptype)}
            </div>
        </div>
    )
}



export default Generic