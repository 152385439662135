import * as THREE from 'three';
import { Object3D } from 'three';
import { AnimAsset, Utils } from '../../utils/Utils';
import { AimvestmentHandler } from './AimvestmentHandler';
import { GrassHandler } from '../GrassHandler';
import { Cloud } from '../Cloud/Cloud';
import { randFloat } from 'three/src/math/MathUtils';
import { BirdController } from '../BirdController';

const building =  require('../../assets/gltf/building_with_animation_11_aug.glb');
const skybox = require('../../assets/2d_images/Skybox/re2.png');
const envmap = require('../../assets/2d_images/Skybox/rural_landscape_1k.png');
const floor =  require('../../assets/gltf/floor.glb');
var modelObj : THREE.Object3D;
var floormodelObj : THREE.Object3D;

// import * as Smoke from '../SmokeHandler';

export class PlatformModule{

    assetsLoadingState = AimvestmentHandler.LoadingAssetState.NotStarted; // 0 = Loading 1 = Loaded 2 = Err
    buildingAnimData?:AnimAsset;
    constructor(aimvestmentHandler:AimvestmentHandler){
        this.assetsLoadingState = AimvestmentHandler.LoadingAssetState.Loading;
        // Utils.Load3DModel(require("../../assets/gltf/SudLife_Env_NotCombined.glb").default).then((model)=>{
        
        Utils.load3DModelWithAnimationData(building).then((model)=>{
            this.buildingAnimData = model;
            this.buildingAnimData.mixer.addEventListener('finished',(e)=>{

                this.ResetBuildingAnim();
             
            });
            modelObj = model.model;
            modelObj.name = "platform";
            modelObj.position.set(-53.5143,0,-124.7333);
            modelObj.scale.set(0.1,0.1,0.1);

            modelObj.traverse((child : any)=>{
                if(child.isMesh){
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            })
            aimvestmentHandler.threejsHandler.renderModule.scene.add(modelObj);

            Utils.Load3DModel(floor).then((floormodel)=>{
                console.log("floor loading .....")
                floormodelObj = floormodel;
                floormodel.name = "floor";
                floormodel.traverse((child : any)=>{
                if(child.isMesh){
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
                }) 
                aimvestmentHandler.threejsHandler.renderModule.scene.add(floormodel);
                floormodel.position.set(-52.910,-9.820,-124.619);//(-532.594,-99.251,-1247.267);
                floormodel.scale.set(0.1,0.1,0.1);
                console.log("x"+floormodel.position.x);
                console.log("y"+floormodel.position.y);
                console.log("z"+floormodel.position.z);

                //Skybox
            console.log("Skybox loading .....")
            
            Utils.LoadTexture(skybox).then((tex)=>{
                console.log("skybox loaded");
                tex.mapping = THREE.EquirectangularReflectionMapping;
                tex.encoding = THREE.sRGBEncoding;
                aimvestmentHandler.threejsHandler.renderModule.scene.background = tex;
                // const geometry = new THREE.SphereGeometry( 500, 60, 40 );
               
                // geometry.scale( - 1, 1, 1 );
                // const material = new THREE.MeshBasicMaterial( { map: tex } );
                // const mesh = new THREE.Mesh( geometry, material );
                // mesh.name = "Skybox";
                // aimvestmentHandler.threejsHandler.renderModule.scene.add( mesh );;
                Utils.LoadTexture(envmap).then((tex)=>{
                    console.log("ENv loaded");
                    tex.mapping = THREE.EquirectangularReflectionMapping;
                    tex.encoding = THREE.sRGBEncoding;
                    aimvestmentHandler.threejsHandler.renderModule.scene.environment = tex;
                    this.assetsLoadingState = AimvestmentHandler.LoadingAssetState.Loaded;
                    
                }).catch((err)=>{
                    console.log("Error on loading skybox",err.message);
                    this.assetsLoadingState = AimvestmentHandler.LoadingAssetState.Err;
                });

                
            }).catch((err)=>{
                console.log("Error on loading skybox",err.message);
                this.assetsLoadingState = AimvestmentHandler.LoadingAssetState.Err;
            });
    
            }).catch((err)=>{
                console.log("Error on loading Floor",err.message);
                this.assetsLoadingState = AimvestmentHandler.LoadingAssetState.Err;
            });

            

           


        }).catch((error)=>{
            console.log("env not loaded:",error);
            this.assetsLoadingState = AimvestmentHandler.LoadingAssetState.Err;
        })
    }
    
    update=(delta:number)=>{
        if(this.buildingAnimData){
            this.buildingAnimData.mixer.update(delta);
        }
    }
    FloorView(b:boolean){

        floormodelObj.visible = b;
        modelObj.visible = !b;
    }
    getAssetsLoadingStatus=()=>{
        return this.assetsLoadingState;
    }
    playBuildingAnim=(floorNo:number)=>{
        if(this.buildingAnimData){
            for(let i=floorNo;i<11;i++){
                this.buildingAnimData.getAction(i).play();   
                this.buildingAnimData.getAction(i).loop = THREE.LoopOnce;
            }
        }
    }
    ResetBuildingAnim=()=>{
        if(this.buildingAnimData){
            // for(let i=0;i<11;i++){
            //     this.buildingAnimData.getAction(i).stop();   
            // }
            this.buildingAnimData.mixer.stopAllAction();
        }
    }
}