import { RenderModule } from "./RenderModule";
import { iModule } from "./iModule";
import { AimvestmentHandler } from "./aimvestment/AimvestmentHandler";
import { EventDispatcher } from "three";

/*
    Modules 
    1.) RenderModule : Done
    2.) AvatarModule : WIP : TANMAY
    3.) PlatformModule : PRATIK -> UPA - VEDANT
    5.) PlayerModule : PRATIK -> UPA - VEDANT
    6.) RemotePlayerModule : PRATIK
    7.) CameraModule : UPA - PRATIK
    8.) ProstheticLegModule : UP -> Vedant
    9.) SelfieModule : UPA
*/
export class ThreejsHandler extends EventDispatcher
{
    renderModule : RenderModule;
    canvasRef: any;
    currModule?: iModule; 
    // updateModule :any;
    static instance : ThreejsHandler;
    static onWindowResizeEvent = { type: 'windowresize',data:{}};

    constructor(canvasRef:any){
        super();
        this.canvasRef = canvasRef;
        this.renderModule = new RenderModule(canvasRef);
        // this.currModule = new AimvestmentHandler(this); 
        ThreejsHandler.instance = this;
    }

    static createInstance = (canvasRef:any)=>{
        if(!ThreejsHandler.instance){
            ThreejsHandler.instance = new ThreejsHandler(canvasRef);
        }
    }

    static getInstance = ()=>{
        return ThreejsHandler.instance;
    }

    start=()=>{
        window.addEventListener('resize', this.renderModule.onWindowResize, false);
        this.renderModule.renderer.setAnimationLoop(this.updateRender);
    }

    stop=()=>{
        window.removeEventListener('resize', this.renderModule.onWindowResize, false);
        this.renderModule.renderer.setAnimationLoop(null);
    }

    onWindowResize = () => {
        this.renderModule.camera.aspect  = window.innerWidth / window.innerHeight;
        ThreejsHandler.onWindowResizeEvent.data = {w:window.innerWidth,h:window.innerHeight};
        this.dispatchEvent(ThreejsHandler.onWindowResizeEvent);
        this.renderModule.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderModule.camera.updateProjectionMatrix();
    }

    updateRender = () => {
       this.updateLogic(this.renderModule.clock.getDelta()); 
       this.renderModule.renderScene();
    };

    updateLogic=(delta:number)=>{
        // this.updateModule();
        if(this.currModule){
            this.currModule.update(delta);
        }
    }

    getCurrentModule=()=>{
        return this.currModule;
    }

    setCurrentModule=(module:iModule)=>{
        this.currModule = module;
    }
}