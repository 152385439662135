import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authActionTypes } from './auth.actionType';

const INIT_STATE = {
    authToken:undefined,
    userData:undefined
}

const authReducer = persistReducer(
    {storage,key:'batman_auth',whitelist:['authToken','userData']},
    (state=INIT_STATE,{type,payload})=>{
        console.log("store dispatch",payload);
        switch(type){
            case authActionTypes.SAVE_AUTH_TOKEN:
                return{
                    ...state,
                    authToken: payload.authToken,
                }
            case authActionTypes.SAVE_USER_DATA:
                return{
                    ...state,
                    userData: payload.userData,
                }
                break;
            default:
                return state;
        }
    }
);

export default authReducer;