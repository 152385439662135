import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
import appdataReducer from './appdata/appdata.reducer';


const RootReducer = combineReducers({
    auth: authReducer,
    appData: appdataReducer
});

export default RootReducer;