import * as THREE from 'three';
import * as TWEEN  from '@tweenjs/tween.js';
import { EventDispatcher } from 'three';
import { AimvestmentHandler } from "./AimvestmentHandler";
// import { DynamicText } from '../DynamicText';
// import { TextStyle } from '../DynamicText';
import { PlayerModule } from './PlayerModule';
import { PlatformModule } from './PlatformModule';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GUI } from 'lil-gui'
import { AimvestmentEvents } from './AimvestmentEvents';
var mouse: THREE.Vector2, raycaster: THREE.Raycaster;
var floorPanel: THREE.Group;
var plane: THREE.Mesh;
var floorNumber: number;
var selectedFloor: number | null;
var camera :THREE.PerspectiveCamera;
var scene : THREE.Scene;
var playerModule : PlayerModule;
var plateformModule : PlatformModule;
//var text : DynamicText;
var resetCamBtn: THREE.Mesh;
var controls : OrbitControls;


export class FloorHandler 
{
    aimvestmentHandler:AimvestmentHandler;

    constructor(aimvestmentHandler : AimvestmentHandler)
    { 
        console.log("FloorHandler called");
        this.aimvestmentHandler = aimvestmentHandler;
        camera = aimvestmentHandler.threejsHandler.renderModule.camera;
        controls = aimvestmentHandler.playerModule.controls;
        scene = aimvestmentHandler.threejsHandler.renderModule.scene;
        playerModule = aimvestmentHandler.playerModule;
        plateformModule = aimvestmentHandler.platfromModule;

        raycaster = new THREE.Raycaster();
        raycaster.layers.set( 1 ); 
          
        mouse = new THREE.Vector2();
        floorPanel = new THREE.Group();
        //text = new DynamicText("floor1",15,"",new THREE.Color(0x000000),TextStyle.bold);
       // scene.add(text.getObject());
        for (let x = 0; x < 11; x++) {
            const geometry = new THREE.PlaneGeometry( 1, 0.3 );
            const material = new THREE.MeshBasicMaterial( {color: 0x000000, side: THREE.FrontSide} );
            plane = new THREE.Mesh( geometry, material );
            floorNumber = x;
            plane.userData.floorNumber = floorNumber;
            plane.position.set(0,(x*0.4) + 1,0);
            plane.layers.enable( 1 );
            floorPanel.add(plane);
        }
        floorPanel.position.set(5,0.5,0);
        scene.add(floorPanel );
        this.addResetCameraButton();
	    // when the mouse click, call the given function
        window.addEventListener('mousemove', this.onMouseMove, false);
        window.addEventListener('click', this.onClick);
	
    }
onMouseMove(event: MouseEvent): void {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
}
 onClick(event: MouseEvent) 
 {
    raycaster.setFromCamera(mouse, camera);
    let intersects = raycaster.intersectObjects(scene.children);
    if (intersects.length > 0) {
      selectedFloor = intersects[0].object.userData.floorNumber;
      console.log(selectedFloor);
      if(selectedFloor !== null){
        if(selectedFloor ==-1){//reset camera



            floorPanel.visible = true;
            plateformModule.FloorView(false);
            playerModule.resetCamera();

        }else{
            
            plateformModule.playBuildingAnim(selectedFloor);
            setTimeout(()=>{
                plateformModule.ResetBuildingAnim();
                plateformModule.FloorView(true);
                floorPanel.visible = false;
                const coords = {x: camera.position.x, y: camera.position.y, z:camera.position.z} // Start at (0, 0)
                controls.enabled = false;
                new TWEEN.Tween(camera.position)
                .to( {x:0, y:5, z:2 }, 2000)
                .yoyo(true)
                .easing(TWEEN.Easing.Cubic.InOut)
                .start()
                playerModule.floorViewCameraSetting();
                controls.enabled = true;
            },3000);
        }
      }
      return;
    }
    selectedFloor = null;
}

addResetCameraButton(){
    const gui = new GUI();
    var obj1 = { Home:()=>{ this.resetCameraClicked() }};       
    gui.add(obj1,'Home'); 
}
resetCameraClicked=()=>{
    floorPanel.visible = true;
    plateformModule.FloorView(false);
    playerModule.resetCamera();
}


update=(delta:number)=>{
    // floorPanel.rotation.setFromRotationMatrix( camera.matrix );
    TWEEN.update();
}
}