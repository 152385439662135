import { appdataActionTypes } from "./appdata.actionType";

const INIT_STATE = {
    gender:0,
    score:0,
    soundState:true,
    replay:false,
    scoreCoinsave:{collected: 0, red:0, green:0, yellow:0},
    scoreProRush:{total_score:0}
}

const appdataReducer = (state = INIT_STATE, {type, payload}) => {
    switch (type) {
        case appdataActionTypes.SAVE_GENDER :
          return {
            ...state,
            gender: payload.gender
          }
        case appdataActionTypes.SAVE_SCORE :
          return {
            ...state,
            score: payload.score
          }
        case appdataActionTypes.SAVE_SOUND_STATE :
          return {
            ...state,
            soundState: payload.soundState
          }
        case appdataActionTypes.SAVE_REPLAY_STATE :
          return {
            ...state,
            replay: payload.replay
          }
        case appdataActionTypes.SAVE_SCORE_COINSAVE :
          return {
            ...state,
            scoreCoinsave: payload.scoreCoinsave
          }
        case appdataActionTypes.SAVE_SCORE_PRORUSH :
          return {
            ...state,
            scoreProRush: payload.scoreProRush
          }  
         default:
             return state;
    }
}

export default appdataReducer;