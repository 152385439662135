import React from "react";
import ReactDOM from "react-dom";
import {Router} from 'react-router';
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import history from './history';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store,{persistor} from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
  
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* <Router history={history}> */}
          <App/>
        {/* </Router> */}
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();