import { iModule } from "../iModule";
import { ThreejsHandler } from "../ThreejsHandler";
import { PlatformModule } from "./PlatformModule";
import { PlayerModule } from "./PlayerModule";
import { EventDispatcher } from "three";
import { AimvestmentEvents } from "./AimvestmentEvents";
import { AimvestmentRender } from "./AimvestmentRender";
import { FloorHandler } from "./FloorHandler";

export class AimvestmentHandler extends EventDispatcher implements iModule {
    static LoadingAssetState = {
        NotStarted:1,
        Loading:2,
        Loaded:3,
        Err:4
    }
    threejsHandler: ThreejsHandler;
    platfromModule: PlatformModule;
    playerModule: PlayerModule;
    aimvestmentRender: AimvestmentRender;
    floorHandler : FloorHandler;
    static DEBUG_MODE: boolean = false;
    assetLoadingRoutine : any;

    constructor(threejsHandler:ThreejsHandler){
        super();
        this.threejsHandler = threejsHandler;
        this.aimvestmentRender = new AimvestmentRender(this);
        this.platfromModule = new PlatformModule(this);
        this.playerModule = new PlayerModule(this);
        this.floorHandler = new FloorHandler(this);
       
        this.dispatchEvent( AimvestmentEvents.onCameraReset );
        
        this.assetLoadingRoutine = setInterval(()=>{
            if(this.getAssetsLoadingStatus() === AimvestmentHandler.LoadingAssetState.Err)
            {
                this.dispatchEvent(AimvestmentEvents.onAssetsLoadingFailed);
                clearInterval(this.assetLoadingRoutine);
                this.assetLoadingRoutine = undefined;
            }
            if(this.getAssetsLoadingStatus() === AimvestmentHandler.LoadingAssetState.Loaded)
            {
                this.dispatchEvent(AimvestmentEvents.onAssetsLoaded);
                clearInterval(this.assetLoadingRoutine);
                this.assetLoadingRoutine = undefined;
            }
        },1000);
    }
    
    onStartGame(): void {
        
        
    }

    onLockPointer() {
        
    }
    onUnLockPointer(){
        
    }
    update(delta: number) {
        this.platfromModule.update(delta);
        this.playerModule.update(delta);
        this.floorHandler.update(delta);
    }

    pause()
    {
        
    }

    unpause()
    {
       
    }
    getAssetsLoadingStatus=()=>{
        if( this.platfromModule.assetsLoadingState === AimvestmentHandler.LoadingAssetState.Err){
            return AimvestmentHandler.LoadingAssetState.Err;
        }
        if( this.platfromModule.assetsLoadingState === AimvestmentHandler.LoadingAssetState.Loaded){
            return AimvestmentHandler.LoadingAssetState.Loaded;
        }
        else{
            return AimvestmentHandler.LoadingAssetState.Loading;
        }
    }

    CameraResetAddEventListner(){
        this.addEventListener('cameraReset', function ( event ) {

            alert( event.message );
        
        } );
    }
}