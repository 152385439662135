import { createStore, applyMiddleware, compose } from 'redux';
import RootReducer from './root.reducer';
import { persistStore } from 'redux-persist';
import HTTPService from '../service/http.service';
// import storage from 'redux-persist/lib/storage';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [];
// const store = createStore(
//     RootReducer,
//     composeEnhancer(applyMiddleware(...middleware))
// );

function ClearLocalStorage(){
    if (!localStorage.getItem("REACT_APP_VERSION") || process.env.REACT_APP_VERSION !== localStorage.getItem("REACT_APP_VERSION")) {
        localStorage.clear();
        localStorage.setItem("REACT_APP_VERSION", process.env.REACT_APP_VERSION)
        console.log("Clear Log");
    }
}
ClearLocalStorage();

const store = createStore(RootReducer, applyMiddleware(...middleware));

export const persistor = persistStore(store, {}, () => {
    
    const { auth } = store.getState();
    if (auth.authToken) {
        HTTPService.saveHeader({ key: 'token', value: `${auth.authToken}` });
        console.log(" Header got saved");
    }
    else {
        //for user who is not logged in
        console.log(" Send user to registration page");
    }
})

export default store;