import * as THREE from 'three';
import * as TWEEN  from '@tweenjs/tween.js';
import { AimvestmentHandler } from "./AimvestmentHandler";
import {OrbitControls}  from 'three/examples/jsm/controls/OrbitControls.js';
import { debug } from 'console';

var cameraFixMinPos: THREE.Vector3;
var cameraFixMaxPos: THREE.Vector3;
var cameraResetPos:THREE.Vector3;
var cameraResetRot:THREE.Euler;
export class PlayerModule {

    aimvestmentHandler : AimvestmentHandler;
    controls : OrbitControls;
    
    constructor(aimvestmentHandler : AimvestmentHandler){ 
        this.aimvestmentHandler = aimvestmentHandler;
        this.controls = new OrbitControls( aimvestmentHandler.threejsHandler.renderModule.camera, aimvestmentHandler.threejsHandler.renderModule.renderer.domElement );

        this.getCamera().position.set( 0, 5, 15);
        cameraResetPos = new THREE.Vector3(this.getCamera().position.x,this.getCamera().position.y,this.getCamera().position.z) ;
        cameraResetRot = new THREE.Euler(this.getCamera().rotation.x,this.getCamera().rotation.y,this.getCamera().rotation.z) ;
        this.controls.target.set(0,0,0);
        this.resetCamera();
        this.controls.update();
    }
   
    update=(delta:number)=>{
        this.controls.update();
        //camera y limitation
     if(this.getCamera().position.y <= cameraFixMinPos.y){
        this.getCamera().position.y = cameraFixMinPos.y;
     }else if(this.getCamera().position.y >= cameraFixMaxPos.y){
        this.getCamera().position.y = cameraFixMaxPos.y;
     }
     TWEEN.update();
    }

    getCamera=()=>{
        return this.aimvestmentHandler.threejsHandler.renderModule.camera
    }
    floorViewCameraSetting=()=>{
        console.log("floorView cam setting");
        cameraFixMinPos = new THREE.Vector3(0,1,0);// used for only y limit now
        cameraFixMaxPos = new THREE.Vector3(0,10,0);// used for only y limit now
     
        this.controls.maxDistance = 10;
        this.controls.maxZoom = 10;
        this.controls.maxAzimuthAngle = 90;
        this.controls.maxPolarAngle = 90;
    }
    resetCamera(){
        cameraFixMinPos = new THREE.Vector3(0,1,0);// used for only y limit now
        cameraFixMaxPos = new THREE.Vector3(0,20,0);// used for only y limit now
     
        this.controls.maxDistance = 20;
        this.controls.maxZoom = 20;
        this.controls.maxAzimuthAngle = 90;
        this.controls.maxPolarAngle = 90;

        new TWEEN.Tween(this.getCamera().position)
        .to( cameraResetPos , 2000)
        .yoyo(true)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        this.getCamera().rotation.set(cameraResetRot.x,cameraResetRot.y,cameraResetRot.z);

    }
}