import { useState } from "react";
import { Routes, Route, BrowserRouter, useSearchParams} from "react-router-dom";
import "./App.css";
import { useEffect } from "react";

import SUDLifeGame from "./components/aimInvestment/SUDLifeGame";

import TagManager from 'react-gtm-module'
import { env } from "process";

function App(props) {

  const basepath = process.env.REACT_APP_BASE_PATH;
  console.log("basepath:"+basepath);

  const tagManagerArgs = {
    gtmId: 'G-PVJW50VW89'
  }

  const [isPortrait, setIsPortrait] = useState(true);
 

  const onWindowResize = () => {
    if (window.innerWidth < window.innerHeight) {
      setIsPortrait(true);
    } else setIsPortrait(false);
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    // const audio = new Audio(require("./assets/sounds/background_track.mp3"));
    // audio.play();
    window.dataLayer = window.dataLayer || [];
    onWindowResize();
    window.addEventListener("resize", onWindowResize, false);

    return () => {
      // audio.pause();
      window.removeEventListener("resize", onWindowResize);
    };
  }, [isPortrait]);

  useEffect(() => {
    const iH = window.innerHeight;
    const iW = window.innerWidth;
    document.querySelector("#root").style.setProperty("--iH", `${iH * 0.01}px`);
  }, []);

  return (
    <>
      <BrowserRouter basename={ process.env.REACT_APP_BASE_PATH }>
        <Routes>
          <Route path="/" element={<SUDLifeGame />} />
          {/* <Route path="/" element= {<GameSelection />} />
          <Route path="/aimvestment" element={<Home />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
          <Route path="/game" element={<SUDLifeGame />} />
          <Route path="/selfie" element={<Selfie />} />
          <Route path="/result" element={<Result />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
