import * as THREE from 'three';
import { MathUtils , Vector3} from 'three';
import { AimvestmentHandler } from "./AimvestmentHandler";
import * as TWEEN from '@tweenjs/tween.js'
import { Utils } from '../../utils/Utils';

export class AimvestmentRender{
    aimvestmentHandler:AimvestmentHandler;
    currentFov = {fov: 0};

    isShaking:boolean = false;
    shakeTarget!: Vector3;
    shakeDurationElapsed:number = 0;
    shakeDuration: number = 0;
    shakeStrength: number = 0;
    shakeIntensity: number = 0;
    currentLookAt!: Vector3;

    constructor(aimvestmentHandler:AimvestmentHandler){
        this.aimvestmentHandler = aimvestmentHandler;
        this.addLights();
    }

    addLights(){
        let isLowerConfig = this.aimvestmentHandler.threejsHandler.renderModule.isLowerConfig;
        //load basic lights
        let dirLight = new THREE.DirectionalLight('#E5FCFF', 3);
        dirLight.position.set(-1.191, 15.012, 14.315);

        if(!isLowerConfig){
            dirLight.castShadow = true; // default false
            dirLight.shadow.mapSize.width = 256; // default
            dirLight.shadow.mapSize.height = 256; // default
            dirLight.shadow.normalBias = 0.1;
            dirLight.shadow.bias = 0;
            dirLight.shadow.radius = 1;
        }
    
        this.getScene().add(dirLight);

        if(!isLowerConfig){
            let hemLight = new THREE.HemisphereLight('#86CBD2',0.50);
            hemLight.groundColor = new THREE.Color("#4B3B35");
            hemLight.position.set(0,10,0);
            this.getScene().add(hemLight);
        }

        let ambientLight = new THREE.AmbientLight('#666666', 1);
        this.getScene().add(ambientLight);
    }

    getScene(){
        return this.aimvestmentHandler.threejsHandler.renderModule.scene;
    }

    getCamera(){
        return this.aimvestmentHandler.threejsHandler.renderModule.camera;
    }

    adjustCamFOV(targetFOV: number, duration:number , delay: number = 0)
    {
        this.currentFov.fov = this.getCamera().fov;
        let that  = this;
        var update = function(){
            that.getCamera().fov = that.currentFov.fov;
            that.getCamera().updateProjectionMatrix();
        }
        var camTween = new TWEEN.Tween( this.currentFov ).to( {fov: targetFOV}, duration ).delay(delay).onUpdate(update).start();
    }

    shakeCam(targetPoint: Vector3, intensity:number, strength: number, duration: number)
    {
        this.shakeTarget = targetPoint;
        this.shakeIntensity = intensity;
        this.shakeStrength = strength;
        this.shakeDuration = duration;
        this.shakeDurationElapsed = 0;
        this.isShaking = true;

        this.currentLookAt = targetPoint;
        this.getCamera().lookAt(targetPoint);

        setTimeout(this.reorientCamForShake.bind(this), intensity);
    }

    reorientCamForShake()
    {
        console.log("Shake");
        if(this.currentLookAt == this.shakeTarget)
        {
            this.currentLookAt = new Vector3(
                this.shakeTarget.x + MathUtils.randInt(-this.shakeStrength, this.shakeStrength), 
                this.shakeTarget.y + MathUtils.randInt(-this.shakeStrength, this.shakeStrength), 
                this.shakeTarget.z + MathUtils.randInt(-this.shakeStrength, this.shakeStrength))
        }
        else
        {
            this.currentLookAt = this.shakeTarget;
        }

        this.getCamera().lookAt(this.currentLookAt);

        this.shakeDurationElapsed += this.shakeIntensity;
        if(this.shakeDurationElapsed > this.shakeDuration)
        {
            this.isShaking = false;
            this.getCamera().lookAt(this.shakeTarget);
        }
        else
        {
            setTimeout(this.reorientCamForShake.bind(this), this.shakeIntensity);
        }
    }
}