import * as THREE from 'three';
import { Euler, Vector3 } from 'three';

export class VideoComponent {
    constructor() {
        console.log("normal video");
    }

    load(videoData, width, height, mesh, onVideoEndEvent, onVideoLoadedEvent, onVidErrorEvent) {
        this.video = document.createElement('video');
        // video.id = 'video';
        // video.type = ' video/ogg; codecs="theora, vorbis" ';
        this.video.src = URL.createObjectURL(videoData);
        this.video.muted = true;
        // this.video.loop=true;
        this.video.setAttribute("playsinline", null);
        if (onVideoEndEvent)
            this.video.onended = onVideoEndEvent;
        if (onVideoLoadedEvent)
            this.video.onloadedmetadata = onVideoLoadedEvent;
        if (onVidErrorEvent)
            this.video.onerror = onVidErrorEvent;
        this.video.load(); // must call after setting/changing source
        //		 this.video.play();

        this.video.width = width;
        this.video.height = height;

        this.videoImage = document.createElement('canvas');
        this.videoImage.width = width;
        this.videoImage.height = height;
        this.videoImageContext = this.videoImage.getContext('2d');
        this.videoImageContext.fillStyle = '#000000';
        //       this.videoImageContext.fillRect( 0, 0, this.videoImage.width, this.videoImage.height );

        this.videoTexture = new THREE.Texture(this.videoImage);
        this.videoTexture.encoding = THREE.sRGBEncoding;
        this.videoTexture.needsUpdate = true;
        this.videoTexture.minFilter = THREE.LinearFilter;
        this.videoTexture.magFilter = THREE.LinearFilter;
        if (mesh !== undefined) {
            this.videoTexture.wrapS = THREE.RepeatWrapping;
            this.videoTexture.wrapT = THREE.RepeatWrapping;
            this.videoTexture.repeat.set(1, -1);
        }

        this.movieMaterial = new THREE.MeshBasicMaterial({ map: this.videoTexture, overdraw: true, side: THREE.DoubleSide, opacity: 1, transparent: true });

        if (mesh === undefined) {
            this.movieGeometry = new THREE.PlaneGeometry(width / height, 1, 4, 4);
            this.movieScreen = new THREE.Mesh(this.movieGeometry, this.movieMaterial);
            // this.movieScreen.scale.set(0.5, 0.5, 0.5); 
            this.movieScreen.rotation.y = Math.PI;
            // this.movieScreen.position.set(0,22.6,-210.7);
            // this.movieScreen.scale.set(10.1,10.1,1);
            // movieScreen2 = new THREE.Mesh( movieGeometry, movieMaterial );
            // movieScreen2.position.set(-125, -195, -109.9);
            // this.scene.add(this.movieScreen);
            // this.movieScreen.visible = false;
            this.videoTexture.wrapS = THREE.RepeatWrapping;
            this.videoTexture.wrapT = THREE.RepeatWrapping;
            this.videoTexture.repeat.set(1, -1);
            return this.movieScreen;
        }
        else {
            // this.videoTexture.flipY = true;
            mesh.material = this.movieMaterial;
        }
    }
    update(delta) {
        if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
            let vRatio = (this.videoImage.height / this.video.videoHeight) * this.video.videoWidth;
            this.videoImageContext.clearRect(0, 0, this.videoImage.width, this.videoImage.height);
            if (this.stopVideo) return;
            this.videoImageContext.drawImage(this.video, 0, 0, vRatio, this.videoImage.height);
            if (this.videoTexture){
                // this.videoTexture.wrapS = THREE.RepeatWrapping;
                // this.videoTexture.wrapT = THREE.RepeatWrapping;
                // this.videoTexture.repeat = new THREE.Vector2 (1,1);
                this.videoTexture.needsUpdate = true;
            }
        }
    }
    stop() {
        this.stopVideo = 1;
        this.video.pause();
        this.videoImageContext.clearRect(0, 0, this.videoImage.width, this.videoImage.height);
    }
    play() {
        this.stopVideo = 0;
        this.video.play();
    }
    setVolume = (flag) => {
        this.video.volume = flag
    }
    handleVolume = (flag) => {
        this.video.muted = false;
        this.video.volume += flag
    }
    setSeekPoint = (seekTime) => {
        this.video.currentTime = seekTime;
    }
    muteVideo = (flag) => {
        this.video.muted = flag;
    }
    restart = () => {
        console.log("restart video")
        this.video.load();
    }
    setActive(flag) {
        this.movieScreen = flag;
    }
    startFrame = () => {
        this.video.currentTime = 0;
    }
    getObject() {
        return this.movieScreen;
    }
    updateMesh(mesh) {
        mesh.material = this.movieMaterial;
    }
    updateMesh90(mesh) {
        mesh.material = this.movieMaterial;
    }
    regisetrVideoEnd(videoEnd) {
        this.video.onended = videoEnd;
    }
    regisetrVideoStart(videoStart) {
        this.video.onplaying = videoStart;
    }
    setLoop(flag) {
        this.video.loop = flag;
    }
}