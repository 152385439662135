export class AimvestmentEvents{
  static onAssetsLoaded = {type: 'assetsLoaded'};
  static onAssetsLoadingFailed = {type: 'assetLoadErr'}
  static onPointerLockEvent = { type: 'pointerlock'};
  static onPointerUnlockEvent = { type: 'pointerunlock'};
  static onScoreUpdate = {type: 'scoreupdate',data:{}};
  static onWaveDataUpdate = {type: 'wavedataUpdate',data:{}};
  static onTimerUpdate = {type: 'timerdataUpdate',data:{}};
  static onGameOver = {type: 'gameover'};
  static onCameraReset = {type:'cameraReset',message: 'vroom vroom!'}
}