import * as THREE from "three";
import { MathUtils, sRGBEncoding , Vector3, WebGLRenderer} from 'three';
import { PostprocessingManager } from "./PostprocessingManager";
import { Utils } from "../utils/Utils";

export class RenderModule{
    
    renderer:WebGLRenderer
    scene: THREE.Scene;
    camera: THREE.PerspectiveCamera;
    clock:THREE.Clock;

    isModuleInitialised=false;
    moduleInitialiseFlag = true;
    initCallBack:any;
    totalAssets = 1;
    loadingCnt=0;
    canvasRef : any;
    cameraFov = 60
    isLowerConfig = false;

    constructor(canvasRef:any){
        this.isLowerConfig =  this.isLowerEndDevice(); 
        //load scene
        this.clock = new THREE.Clock();
        this.scene = new THREE.Scene();

        //load rendere
        if(false && this.isLowerConfig)
            this.renderer = new THREE.WebGLRenderer({ canvas: canvasRef, antialias: false, alpha: false });
        else
            this.renderer = new THREE.WebGLRenderer({ canvas: canvasRef, antialias: true, alpha: true, powerPreference: "high-performance" });

        this.renderer.sortObjects = false
        
        this.renderer.physicallyCorrectLights = false;
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.toneMapping = THREE.LinearToneMapping;
		this.renderer.toneMappingExposure = 0.7;
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        let size = window.innerWidth;
        if(window.innerWidth > window.innerHeight)
            size = window.innerWidth;
        else
            size = window.innerHeight;

        size = 1920 / size;

        if(size > 1){
            size = 1;
        }

        console.log("current size of a window:"+size);

        this.renderer.setPixelRatio(window.devicePixelRatio * size);

        if(this.isLowerConfig)
            this.renderer.setPixelRatio(window.devicePixelRatio * size * 0.9);
        else{
            this.renderer.setPixelRatio(window.devicePixelRatio * size * 0.9);
        }

        //shadow settingS
        if(!this.isLowerConfig){
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; 
        }

        document.body.appendChild(this.renderer.domElement);

        //load camera reference
        this.camera = new THREE.PerspectiveCamera(this.cameraFov, window.innerWidth / window.innerHeight, 0.3, 100000);
        this.scene.add(this.camera);

        // console.log("cannvas",canvasRef);
        this.canvasRef = canvasRef;
    }
    onWindowResize = () => {

            this.camera.aspect  = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
    renderScene = () => {
        if (this.renderer) this.renderer.render(this.scene, this.camera);
    };

    getCanvasWidth = () =>{
        return window.innerWidth;
    }
    
    getCanvasHeight = ()=>{
        return window.innerHeight;
    }

    isLowerEndDevice = () => {
        return false;

        let ram = (navigator as any).deviceMemory;
        if(ram === undefined){
            return false;
        }
        console.log("Device Memory:"+ram);
        if(ram < 6)
            return true;
        // if(Utils.isFromMobile() && Utils.getMobileOS() === 'Android'){
           
        // }
        return false;
    }
}

